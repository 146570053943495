import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const PortfolioValen = () => {
  return (
    <Layout bgClass="bg-valen-blue" fgMode="light">
      <Seo 
        title="Valen Industries"
        description="The ESO Master Controller"
      />
      <section className="grid w-full bg-valen-blue grid-rows-portfolio">

      </section>
    </Layout>
 );
};
export default PortfolioValen
